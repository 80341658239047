<template>
  <div>
    <topHeader :userData="user" />
    <div class="explore">
      <div class="explore__explorebgc">
        <div class="container">
          <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-3">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form__forminpsearch"
                  placeholder="Name or profession"
                  v-model="searchText"
                  @change="onSearch"
                />
              </div>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-9">
              <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                  <div class="form-group">
                    <select
                      class="form-control form__forminp"
                      @change="handleSearchFilter($event, 'country')"
                    >
                      <option value="">Country</option>
                      <template v-for="(country, index) of countries">
                        <option :value="country.name" :key="index">
                          {{ country.name }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                  <div class="form-group">
                    <select
                      class="form-control form__forminp"
                      @change="handleSearchFilter($event, 'state')"
                    >
                      <option value="">State</option>
                      <template v-for="(state, index) of states">
                        <option :value="state.name" :key="index">
                          {{ state.name }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div>
                <template v-if="!this.searchCompany">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <div class="form-group">
                      <select
                        class="form-control form__forminp"
                        @change="handleSearchFilter($event, 'gender')"
                      >
                        <option value="">Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>
                </template>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                  <div class="form-group">
                    <select
                      class="form-control form__forminp"
                      @change="handleSearchFilter($event, 'expertise')"
                    >
                      <option value="">Expertise</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="profile-and-company-tab container" style="paddingTop: 20px">
        <div
          @click="selectTab('profile')"
          :class="{ 'active-explore-tab': searchProfile }"
        >
          Profiles
        </div>
        <div
          @click="selectTab('company')"
          :class="{ 'active-explore-tab': searchCompany }"
        >
          Companies
        </div>
      </div>
    </div>

    <div class="container" v-if="showSearchText">
      <div class="__sersh">
        <p class="__sershp text-center">You searched for “{{ searchText }}”</p>
      </div>
    </div>
    <div class="container">
      <template v-for="user of this.first">
        <div class="explore__card __pos-rel" :key="user.id">
          <div class="__locateline"></div>
          <template v-if="user.type === 'talent'">
            <div class="__maiflx">
              <div
                class="__imgexp"
                :style="{
                  'background-image': user.avatar
                    ? 'url(' + user.avatar + ')'
                    : 'url(' + require('../assets/img/default-img.jpg') + ')',
                }"
              ></div>
              <div class="__midwod">
                <router-link
                  :to="`/${user.slug}`"
                  class="__namexp"
                  rout
                >
                  {{ user.fullName }}
                  <!-- <img src="../assets/img/nameimg.png" /> -->
                </router-link>
                <!-- <p class="__namexp1">Professional Model</p> -->

                <div class="__diflex">
                  <div class="__flbot">
                    <img src="../assets/img/epushpin.png" />
                    <p>
                      Location: {{ user.profile.city || "N/A" }},
                      {{ user.profile.country || "N/A" }}
                    </p>
                  </div>
                  <div class="__flbot">
                    <img src="../assets/img/ebriefcase.png" />
                    <p>
                      Work Preferences:
                      {{ user.profile.work_preferences || "N/A" }}
                    </p>
                  </div>
                </div>

                <p class="__detailexp" v-if="user && user.profile">
                  {{
                    user.profile.bio && user.profile.bio.length > 500
                      ? `${user.profile.bio.substr(0, 500)}...`
                      : user.profile.bio
                  }}
                  {{ !user.profile.bio ? "N/A" : null }}
                </p>
              </div>
              <div class="__sowid">
                <div class="explore__social">
                  <a
                    :href="`${user.profile.instagram_url}`"
                    v-if="user.profile.instagram_url"
                    target="_blank"
                  >
                    <img src="../assets/img/instagram.png" />
                  </a>
                  <a
                    :href="`${user.profile.twitter_url}`"
                    v-if="user.profile.twitter_url"
                    target="_blank"
                  >
                    <img src="../assets/img/twitter.png" />
                  </a>
                  <a
                    :href="`${user.profile.linkedin_url}`"
                    v-if="user.profile.linkedin_url"
                    target="_blank"
                  >
                    <img src="../assets/img/linkedin.png" />
                  </a>
                  <a
                    :href="`${user.profile.facebook_url}`"
                    v-if="user.profile.facebook_url"
                    target="_blank"
                  >
                    <img src="../assets/img/facebook.png" />
                  </a>
                  <p class="explore__social-avp">Available for mentorship</p>
                  <template v-if="user">
                    <router-link
                      :to="`/${user.slug}`"
                      class="btn explore__social-link"
                      >Book Me</router-link
                    >
                  </template>
                </div>
              </div>
            </div>
          </template>

          <template v-if="user.type !== 'talent'">
            <div class="__maiflx">
              <div
                class="__imgexp"
                :style="{
                  'background-image': user.logo
                    ? 'url(' + user.logo + ')'
                    : 'url(' + require('../assets/img/default-img.jpg') + ')',
                }"
              ></div>
              <div class="__midwod">
                <router-link
                  :to="`/${user.slug}/company-profile`"
                  class="__namexp"
                >
                  {{ user.name }}
                  <!-- <img src="../assets/img/nameimg.png" /> -->
                </router-link>
                <!-- <p class="__namexp1" style="marginTop: 5px;">{{ user.about && user.about.length > 0 ?  `${user.about.substr(0,20)}...`: '' }}</p> -->
                <div class="__diflex">
                  <div class="__flbot">
                    <img src="../assets/img/epushpin.png" />
                    <p>
                      Location: {{ user.city || "N/A" }},
                      {{ user.country || "N/A" }}
                    </p>
                  </div>
                </div>
                <p class="__detailexp">
                  {{ user.about || "N/A" }}
                </p>
              </div>
              <div class="__sowid">
                <div class="explore__social">
                  <a
                    :href="`${user.instagram_url}`"
                    v-if="user.instagram_url"
                    target="_blank"
                  >
                    <img src="../assets/img/instagram.png" />
                  </a>
                  <a
                    :href="`${user.twitter_url}`"
                    v-if="user.twitter_url"
                    target="_blank"
                  >
                    <img src="../assets/img/twitter.png" />
                  </a>
                  <a
                    :href="`${user.linkedin_url}`"
                    v-if="user.linkedin_url"
                    target="_blank"
                  >
                    <img src="../assets/img/linkedin.png" />
                  </a>
                  <a
                    :href="`${user.facebook_url}`"
                    v-if="user.facebook_url"
                    target="_blank"
                  >
                    <img src="../assets/img/facebook.png" />
                  </a>
                  <router-link
                    :to="`/${user.slug}/company-profile`"
                    class="btn explore__social-link"
                    >View Company</router-link
                  >
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
    <div class="__spacet2p"></div>

    <div class="__exbg">
      <div class="container">
        <div class="row">
          <div class="col-xl-9 col-lg-9 col-md-8 col-sm-8">
            <p class="professional__meet">
              Hey <template v-if="user">{{ user.fullName }},</template
              ><template v-if="!user">Guest ,</template> we found some profiles
              that might be of interest to you.
            </p>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-4 __shifr">
            <span class="cust-arrow cust-next">
              <img src="../assets/img/leftar.png" /> </span
            >&nbsp;&nbsp;

            <span class="cust-arrow1 cust-prev">
              <img src="../assets/img/rightar.png" />
            </span>
          </div>
        </div>
        <div class="customer-logos slider" v-if="this.interestingProfiles.length">
          <div
            class="slide"
            v-for="profile of this.interestingProfiles"
            :key="profile.id"
          >
            <div
              class="__owl-carousel_card"
              :style="{
                'background-image': `linear-gradient( 358.23deg, #000000 3.46%, rgba(0, 0, 0, 0) 46.91%), url(${
                  profile.avatar ? profile.avatar : profile.logo
                })`,
              }"
            >
              <p class="__owl-carousel_card-name">
                {{ profile.fullName ? profile.fullName : profile.name }}
              </p>
              <!-- <p class="__owl-carousel_card-name1">Product Designer</p> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <template v-for="user of this.second">
        <div class="explore__card __pos-rel" :key="user.id">
          <div class="__locateline"></div>
          <template v-if="user.type === 'talent'">
            <div class="__maiflx">
              <div
                class="__imgexp"
                :style="{
                  'background-image': user.avatar
                    ? 'url(' + user.avatar + ')'
                    : 'url(' + require('../assets/img/default-img.jpg') + ')',
                }"
              ></div>
              <div class="__midwod">
                <router-link
                  :to="`/${user.slug}`"
                  class="__namexp"
                  rout
                >
                  {{ user.fullName }}
                  <!-- <img src="../assets/img/nameimg.png" /> -->
                </router-link>
                <!-- <p class="__namexp1">Professional Model</p> -->

                <div class="__diflex">
                  <div class="__flbot">
                    <img src="../assets/img/epushpin.png" />
                    <p>
                      Location: {{ user.profile.city || "N/A" }},
                      {{ user.profile.country || "N/A" }}
                    </p>
                  </div>
                  <div class="__flbot">
                    <img src="../assets/img/ebriefcase.png" />
                    <p>
                      Work Preferences:
                      {{ user.profile.work_preferences || "N/A" }}
                    </p>
                  </div>
                </div>

                <p class="__detailexp" v-if="user && user.profile">
                  <!-- {{ user.profile.bio || "N/A" }} -->
                  {{
                    user.profile.bio && user.profile.bio.length > 500
                      ? `${user.profile.bio.substr(0, 500)}...`
                      : user.profile.bio
                  }}
                  {{ !user.profile.bio ? "N/A" : null }}
                </p>
              </div>
              <div class="__sowid">
                <div class="explore__social">
                  <a
                    :href="`${user.profile.instagram_url}`"
                    v-if="user.profile.instagram_url"
                    target="_blank"
                  >
                    <img src="../assets/img/instagram.png" />
                  </a>
                  <a
                    :href="`${user.profile.twitter_url}`"
                    v-if="user.profile.twitter_url"
                    target="_blank"
                  >
                    <img src="../assets/img/twitter.png" />
                  </a>
                  <a
                    :href="`${user.profile.linkedin_url}`"
                    v-if="user.profile.linkedin_url"
                    target="_blank"
                  >
                    <img src="../assets/img/linkedin.png" />
                  </a>
                  <a
                    :href="`${user.profile.facebook_url}`"
                    v-if="user.profile.facebook_url"
                    target="_blank"
                  >
                    <img src="../assets/img/facebook.png" />
                  </a>
                  <p class="explore__social-avp">Available for mentorship</p>
                  <template v-if="user">
                    <router-link
                      :to="`/${user.slug}`"
                      class="btn explore__social-link"
                      >Book Me</router-link
                    >
                  </template>
                </div>
              </div>
            </div>
          </template>

          <template v-if="user.type !== 'talent'">
            <div class="__maiflx">
              <div
                class="__imgexp"
                :style="{
                  'background-image': user.logo
                    ? 'url(' + user.logo + ')'
                    : 'url(' + require('../assets/img/default-img.jpg') + ')',
                }"
              ></div>
              <div class="__midwod">
                <router-link
                  :to="`/${user.slug}/company-profile`"
                  class="__namexp"
                >
                  {{ user.name }}
                  <!-- <img src="../assets/img/nameimg.png" /> -->
                </router-link>
                <!-- <p class="__namexp1" style="marginTop: 5px;">{{ user.about && user.about.length > 0 ?  `${user.about.substr(0,20)}...`: '' }}</p> -->
                <div class="__diflex">
                  <div class="__flbot">
                    <img src="../assets/img/epushpin.png" />
                    <p>
                      Location: {{ user.city || "N/A" }},
                      {{ user.country || "N/A" }}
                    </p>
                  </div>
                </div>
                <p class="__detailexp" v-if="user && user.profile">
                  <!-- {{ user.profile.bio || "N/A" }} -->
                  {{
                    user.profile.bio && user.profile.bio.length > 500
                      ? `${user.profile.bio.substr(0, 500)}...`
                      : user.profile.bio
                  }}
                  {{ !user.profile.bio ? "N/A" : null }}
                </p>
              </div>
              <div class="__sowid">
                <div class="explore__social">
                  <a
                    :href="`${user.instagram_url}`"
                    v-if="user.instagram_url"
                    target="_blank"
                  >
                    <img src="../assets/img/instagram.png" />
                  </a>
                  <a
                    :href="`${user.twitter_url}`"
                    v-if="user.twitter_url"
                    target="_blank"
                  >
                    <img src="../assets/img/twitter.png" />
                  </a>
                  <a
                    :href="`${user.linkedin_url}`"
                    v-if="user.linkedin_url"
                    target="_blank"
                  >
                    <img src="../assets/img/linkedin.png" />
                  </a>
                  <a
                    :href="`${user.facebook_url}`"
                    v-if="user.facebook_url"
                    target="_blank"
                  >
                    <img src="../assets/img/facebook.png" />
                  </a>
                  <router-link
                    :to="`/${user.slug}/company-profile`"
                    class="btn explore__social-link"
                    >View Company</router-link
                  >
                </div>
              </div>
            </div>
          </template>
        </div>
        <!-- <div class="explore__card __pos-rel" :key="user.id">
          <div class="__locateline"></div>
          <div class="__maiflx">
            <div
              class="__imgexp"
              :style="{
                'background-image':
                  'url(' + require('../assets/img/expimg.png') + ')',
              }"
            ></div>
            <div class="__midwod">
              <p class="__namexp">
                {{ user.fullName }} <img src="../assets/img/nameimg.png" />
              </p>
              <p class="__namexp1">Professional Model</p>

              <div class="__diflex">
                <div class="__flbot">
                  <img src="../assets/img/etrophy.png" />
                  <p>Experience: 7 years (Senior)</p>
                </div>
                <div class="__flbot">
                  <img src="../assets/img/epushpin.png" />
                  <p>
                    Location: {{ user.profile.city || "N/A" }},
                    {{ user.profile.country || "N/A" }}
                  </p>
                </div>
                <div class="__flbot">
                  <img src="../assets/img/ebriefcase.png" />
                  <p>
                    Work Preferences:
                    {{ user.profile.work_preferences || "N/A" }}
                  </p>
                </div>
              </div>

              <p class="__detailexp">
                {{ user.profile.bio || "N/A" }}
              </p>
            </div>
            <div class="__sowid">
              <div class="explore__social">
                <a
                  :href="`${user.profile.instagram_url}`"
                  v-if="user.profile.instagram_url"
                >
                  <img src="../assets/img/instagram.png" />
                </a>
                <a
                  :href="`${user.profile.twitter_url}`"
                  v-if="user.profile.twitter_url"
                >
                  <img src="../assets/img/twitter.png" />
                </a>
                <a
                  :href="`${user.profile.linkedin_url}`"
                  v-if="user.profile.linkedin_url"
                >
                  <img src="../assets/img/linkedin.png" />
                </a>
                <a
                  :href="`${user.profile.facebook_url}`"
                  v-if="user.profile.facebook_url"
                >
                  <img src="../assets/img/facebook.png" />
                </a>
                <p class="explore__social-avp">Available for mentorship</p>

                <a href="" class="btn explore__social-link">Book Me</a>
              </div>
            </div>
          </div>
        </div> -->
      </template>
    </div>

    <div class="get">
      <div class="container">
        <div class="row __flexsmail">
          <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 __mobidown">
            <div class="get__comp">
              <p class="get__comp-getcomp">
                Get your company <span>featured today</span>
              </p>
              <p class="get__comp-getcomp1">
                There are are more than 80 Expert Networks specializing in
                everything from buy-side research pharmaceuticals. from buy-side
                research to taxes There are are more than 80 Expert Networks
                specializing in everything from buy
              </p>

              <!-- <a href="" class="btn get__comp-getbtn">Get Started</a> -->
            </div>
          </div>
          <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 __mobiup">
            <div class="get__getbgimg">
              <div class="row">
                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                  <p class="get__getbgimg-company">XYZ Company</p>
                  <p class="get__getbgimg-company1">
                    Open for mentorship & Work
                  </p>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                  <!-- <a href="" class="btn get__getbgimg-startbtn">Start Now</a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <template v-for="user of this.third">
        <div class="explore__card __pos-rel" :key="user.id">
          <div class="__locateline"></div>
          <template v-if="user.type === 'talent'">
            <div class="__maiflx">
              <div
                class="__imgexp"
                :style="{
                  'background-image': user.avatar
                    ? 'url(' + user.avatar + ')'
                    : 'url(' + require('../assets/img/default-img.jpg') + ')',
                }"
              ></div>
              <div class="__midwod">
                <router-link
                  :to="`/${user.slug}`"
                  class="__namexp"
                  rout
                >
                  {{ user.fullName }}
                  <!-- <img src="../assets/img/nameimg.png" /> -->
                </router-link>
                <!-- <p class="__namexp1">Professional Model</p> -->

                <div class="__diflex">
                  <div class="__flbot">
                    <img src="../assets/img/epushpin.png" />
                    <p>
                      Location: {{ user.profile.city || "N/A" }},
                      {{ user.profile.country || "N/A" }}
                    </p>
                  </div>
                  <div class="__flbot">
                    <img src="../assets/img/ebriefcase.png" />
                    <p>
                      Work Preferences:
                      {{ user.profile.work_preferences || "N/A" }}
                    </p>
                  </div>
                </div>

                <p class="__detailexp" v-if="user && user.profile">
                  {{
                    user.profile.bio && user.profile.bio.length > 500
                      ? `${user.profile.bio.substr(0, 500)}...`
                      : user.profile.bio
                  }}
                  {{ !user.profile.bio && "N/A" }}
                </p>
              </div>
              <div class="__sowid">
                <div class="explore__social">
                  <a
                    :href="`${user.profile.instagram_url}`"
                    v-if="user.profile.instagram_url"
                    target="_blank"
                  >
                    <img src="../assets/img/instagram.png" />
                  </a>
                  <a
                    :href="`${user.profile.twitter_url}`"
                    v-if="user.profile.twitter_url"
                    target="_blank"
                  >
                    <img src="../assets/img/twitter.png" />
                  </a>
                  <a
                    :href="`${user.profile.linkedin_url}`"
                    v-if="user.profile.linkedin_url"
                    target="_blank"
                  >
                    <img src="../assets/img/linkedin.png" />
                  </a>
                  <a
                    :href="`${user.profile.facebook_url}`"
                    v-if="user.profile.facebook_url"
                    target="_blank"
                  >
                    <img src="../assets/img/facebook.png" />
                  </a>
                  <p class="explore__social-avp">Available for mentorship</p>
                  <template v-if="user">
                    <router-link
                      :to="`/${user.slug}`"
                      class="btn explore__social-link"
                      >Book Me</router-link
                    >
                  </template>
                </div>
              </div>
            </div>
          </template>

          <template v-if="user.type !== 'talent'">
            <div class="__maiflx">
              <div
                class="__imgexp"
                :style="{
                  'background-image': user.logo
                    ? 'url(' + user.logo + ')'
                    : 'url(' + require('../assets/img/default-img.jpg') + ')',
                }"
              ></div>
              <div class="__midwod">
                <router-link
                  :to="`/${user.slug}/company-profile`"
                  class="__namexp"
                >
                  {{ user.name }}
                  <!-- <img src="../assets/img/nameimg.png" /> -->
                </router-link>
                <p class="__namexp1" style="margintop: 5px">
                  {{
                    user.about && user.about.length > 0
                      ? `${user.about.substr(0, 20)}...`
                      : ""
                  }}
                </p>
                <div class="__diflex">
                  <div class="__flbot">
                    <img src="../assets/img/epushpin.png" />
                    <p>
                      Location: {{ user.city || "N/A" }},
                      {{ user.country || "N/A" }}
                    </p>
                  </div>
                </div>
                <p class="__detailexp" v-if="user && user.profile">
                  {{ user.profile.bio || "N/A" }}
                </p>
              </div>
              <div class="__sowid">
                <div class="explore__social">
                  <a
                    :href="`${user.instagram_url}`"
                    v-if="user.instagram_url"
                    target="_blank"
                  >
                    <img src="../assets/img/instagram.png" />
                  </a>
                  <a
                    :href="`${user.twitter_url}`"
                    v-if="user.twitter_url"
                    target="_blank"
                  >
                    <img src="../assets/img/twitter.png" />
                  </a>
                  <a
                    :href="`${user.linkedin_url}`"
                    v-if="user.linkedin_url"
                    target="_blank"
                  >
                    <img src="../assets/img/linkedin.png" />
                  </a>
                  <a
                    :href="`${user.facebook_url}`"
                    v-if="user.facebook_url"
                    target="_blank"
                  >
                    <img src="../assets/img/facebook.png" />
                  </a>
                  <router-link
                    :to="`/${user.slug}/company-profile`"
                    class="btn explore__social-link"
                    >View Company</router-link
                  >
                </div>
              </div>
            </div>
          </template>
        </div>
        <!-- <div class="explore__card __pos-rel" :key="user.id">
          <div class="__locateline"></div>
          <div class="__maiflx">
            <div
              class="__imgexp"
              :style="{
                'background-image':
                  'url(' + require('../assets/img/expimg.png') + ')',
              }"
            ></div>
            <div class="__midwod">
              <p class="__namexp">
                {{ user.fullName }} <img src="../assets/img/nameimg.png" />
              </p>
              <p class="__namexp1">Professional Model</p>

              <div class="__diflex">
                <div class="__flbot">
                  <img src="../assets/img/etrophy.png" />
                  <p>Experience: 7 years (Senior)</p>
                </div>
                <div class="__flbot">
                  <img src="../assets/img/epushpin.png" />
                  <p>
                    Location: {{ user.profile.city || "N/A" }},
                    {{ user.profile.country || "N/A" }}
                  </p>
                </div>
                <div class="__flbot">
                  <img src="../assets/img/ebriefcase.png" />
                  <p>
                    Work Preferences:
                    {{ user.profile.work_preferences || "N/A" }}
                  </p>
                </div>
              </div>

              <p class="__detailexp">
                {{ user.profile.bio || "N/A" }}
              </p>
            </div>
            <div class="__sowid">
              <div class="explore__social">
                <a
                  :href="`${user.profile.instagram_url}`"
                  v-if="user.profile.instagram_url"
                >
                  <img src="../assets/img/instagram.png" />
                </a>
                <a
                  :href="`${user.profile.twitter_url}`"
                  v-if="user.profile.twitter_url"
                >
                  <img src="../assets/img/twitter.png" />
                </a>
                <a
                  :href="`${user.profile.linkedin_url}`"
                  v-if="user.profile.linkedin_url"
                >
                  <img src="../assets/img/linkedin.png" />
                </a>
                <a
                  :href="`${user.profile.facebook_url}`"
                  v-if="user.profile.facebook_url"
                >
                  <img src="../assets/img/facebook.png" />
                </a>
                <p class="explore__social-avp">Available for mentorship</p>

                <a href="" class="btn explore__social-link">Book Me</a>
              </div>
            </div>
          </div>
        </div> -->
      </template>
    </div>

    <div class="text-center">
      <!-- <a href="" class="btn __loadbtn">Load More Result</a> -->
    </div>
    <div class="__spacet5p"></div>
    <topFooter />
  </div>
</template>

<script>
import $ from "jquery";
import { mapActions, mapGetters } from "vuex";
import csc from "country-state-city";

import topHeader from "../components/topHeader.vue";
import Loader from "../components/loader";
import topFooter from "../components/topFooter";
import UserMixin from "../mixins/usersMixins";

import { errorHandler } from "../../utils/helpers";

export default {
  components: {
    topHeader,
    Loader,
    topFooter,
  },
  computed: mapGetters(["user"]),
  mixins: [UserMixin],
  data() {
    return {
      professionalsAboveCarousel: [],
      professionalsBelowCarousel: [],
      first: [],
      second: [],
      third: [],
      searchFilter: {},
      searchTerm: "",
      searchProfile: true,
      searchCompany: false,
      countries: [],
      states: [],
      searchText: "",
      showSearchText: false,
      interestingProfiles: [],
    };
  },
  methods: {
    ...mapActions(["fetchAllUsers", "handleSearch", "fetchSingleUser"]),
    async onSearch(event) {
      this.searchTerm = `term=${event.target.value}`;

      if (this.searchCompany) {
        this.searchTerm = `term=${event.target.value}&type=${"companies"}`;
      }
      const { data: users } = await this.handleSearch(this.searchTerm);

      this.showSearchText = true;

      if (users.length <= 4) {
        this.first = users;
      } else {
        this.first = users.slice(0, Math.floor(users.length / 4));
        this.second = users.slice(
          this.first.length,
          this.first.length + Math.floor(users.length / 4)
        );
        this.third = users.slice(this.first.length + this.second.length);
      }
    },

    async handleSearchFilter(event, key) {
      if (key === "country") {
        const isoCode = this.countries.find(
          (country) => country.name === event.target.value
        ).isoCode;
        this.states = csc.getStatesOfCountry(`${isoCode}`);
      }
      this.searchFilter[key] = event.target.value;
      const filter = Object.keys(this.searchFilter).join(",");
      const values = Object.values(this.searchFilter).join(",");

      let searchUrl = `term=${this.searchTerm}&filter=${filter}&values=${values}`;

      if (this.searchCompany) {
        searchUrl = `term=${this.searchTerm}&filter=${filter}&values=${values}&type=companies`;
      }

      const { data: users } = await this.handleSearch(`${searchUrl}`);

      if (users.length <= 4) {
        this.first = users;
      } else {
        this.first = users.slice(0, 4);
        this.second = users.slice(this.first.length, this.first.length + 4);
        this.third = users.slice(this.first.length + this.second.length);
      }
    },

    async selectTab(value, searchQuery = "") {
      this.interestingProfiles = [];
      this.first = [];
      this.second = [];
      this.third = [];
      this.searchCompany = value === "company" ? true : false;
      this.searchProfile = !this.searchCompany;
      this.showSearchText = false;
      this.searchText = searchQuery;
      localStorage.setItem("selectedExploreTab", value);

      try {
        if (value === "company") {
          const { data: users } = await this.handleSearch(
            `term=${searchQuery}&filter=${""}&values=${""}&type=${"companies"}`
          );

          if (users.length <= 4) {
            this.first = users;
          } else {
            this.first = users.slice(0, Math.floor(users.length / 4));
            this.second = users.slice(
              this.first.length,
              this.first.length + Math.floor(users.length / 4)
            );
            this.third = users.slice(this.first.length + this.second.length);
          }
          this.interestingProfiles = users.slice(0, 10);
        } else {
          const { data: users } = await this.handleSearch(
            `term=${searchQuery}&filter=${""}&values=${""}`
          );

          if (users.length <= 4) {
            this.first = users;
          } else {
            this.first = users.slice(0, Math.floor(users.length / 4));
            this.second = users.slice(
              this.first.length,
              this.first.length + Math.floor(users.length / 4)
            );
            this.third = users.slice(this.first.length + this.second.length);
          }
          this.interestingProfiles = users.slice(0, 10);
        }
      } catch (error) {
        errorHandler(error, this.$toast);
      } finally {
        this.loadCarousels();
      }
    },

    loadCarousels() {
      if (this.interestingProfiles.length) {
        console.log("CALLED CAROUSELS ");
        $(document).ready(() => {
          $(".customer-logos").slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 1500,
            prevArrow: $(".cust-prev"),
            nextArrow: $(".cust-next"),
            pauseOnHover: true,
            centerMode: true,
            centerPadding: "130px",
            responsive: [
              {
                breakpoint: 800,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 3,
                  centerMode: true,
                  centerPadding: "90px",
                },
              },
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                  centerMode: true,
                  centerPadding: "150px",
                },
              },
              {
                breakpoint: 520,
                settings: {
                  slidesToShow: 1,
                  centerMode: true,
                  centerPadding: "0px",
                },
              },
            ],
          });
        });
      }
    },
  },

  async mounted() {
    this.countries = csc.getAllCountries();

    const searchQuery = this.$route.query.search || "";

    if (searchQuery.length) {
      this.searchText = searchQuery;
      this.showSearchText = true;
    }

    try {
      await this.selectTab(
        localStorage.getItem("selectedExploreTab"),
        searchQuery
      );
    } catch (error) {
      errorHandler(error, this.$toast);
    } finally {
		  this.loadCarousels();
    }
  },
};
</script>

<style lang="scss">
.profile-and-company-tab {
  display: flex;
  justify-content: space-between;

  & > div {
    padding: 0 40px 11px 40px;
    cursor: pointer;
  }
}

.active-explore-tab {
  border-bottom: 3px solid #2357bb;
}
</style>
